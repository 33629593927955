.small-container {
  margin-top: 25px;
  max-width: 500px;
  margin: 0 auto;
}

.nav-link, .nav-link:visited, .nav-link:active, .nav-link:hover, .nav-link:focus {
  color: black;
  text-decoration: none;
  margin-right: 15px;
}

.post-comment {
  margin: 5px;
  margin-left: 75px;
  background-color: #ccc;
  padding: 10px;
  white-space: pre-line;
}

.post-content {
  margin: 5px;
  background-color: #ccc;
  padding: 10px;
  white-space: pre-line;
}

.login-box {
  padding: 25px;
  background-color: #ccc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bot {
  background-color: #ccc;
  padding: 25px;
}